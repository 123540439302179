import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchAdminProjects,
  fetchDesigners,
  searchAdminProjects,
} from "../../actions";
import ProjectColumns from "../project/ProjectColumns";
import getStatusKeys from "../../utils/getStatusKeys";
import Loading from "../Loading";

export default function ProjectListAdmin() {
  const projects = useSelector((state) => state.admin.projects);
  const designers = useSelector((state) => state.admin.designers);
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const [data, setData] = useState({
    order: "asc",
    status: "",
    assigned: undefined,
  });

  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [priority, setPriority] = useState("");

  useEffect(() => {
    const loadAssets = async () => {
      await dispatch(fetchAdminProjects(data));
      await dispatch(fetchDesigners());
      setLoading(false);
    };

    loadAssets();
  }, []);

  useEffect(() => {
    const loadAssets = async () => {
      setLoading(true);
      await dispatch(fetchAdminProjects({ ...data, status, priority }));
      setLoading(false);
    };

    loadAssets();
  }, [status, priority]);

  const handleSearch = async (event) => {
		event.preventDefault();
		if (search === "") {
			handleReset(event);
		}
    try {
      setLoading(true);
      if (!search) {
        await dispatch(
          fetchAdminProjects({
            order: "asc",
            status: "",
            assigned: undefined,
          })
        );
        return;
      }
      await dispatch(searchAdminProjects(search));
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const handleReset = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      await dispatch(
        fetchAdminProjects({
          order: "asc",
          status: "",
          assigned: undefined,
        })
      );
      setSearch("");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  return (
    <React.Fragment>
      <div className="dashboard-filter dashboard-filter--project-manager container">
        <div className="dashboard-filter__item">
          <label htmlFor="status" className="dashboard-filter__title">
            By Priority
          </label>
          <select
            className="button button--filter button--dark"
            id="status"
            value={priority}
            onChange={(event) => setPriority(event.target.value)}
          >
            <option value="">View All</option>
            <option value="0">Low</option>
            <option value="1">Medium</option>
            <option value="2">High</option>
          </select>
        </div>

        <div className="dashboard-filter__item" style={{ display: "none" }}>
          <label htmlFor="status" className="dashboard-filter__title">
            Status
          </label>
          <select
            className="button button--filter button--dark"
            id="status"
            value={status}
            onChange={(event) => setStatus(event.target.value)}
          >
            <option value="">View All</option>
            {getStatusKeys().map((status) => (
              <option key={status.value} value={status.value}>
                {status.display}
              </option>
            ))}
          </select>
        </div>
        <form onSubmit={handleSearch} className="dashboard-filter__search">
          <div className="dashboard-filter__search__bar">
            <label htmlFor="search" className="dashboard-filter__title">
              Search Projects
            </label>
            <input
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              className="dashboard-filter__search__input"
            ></input>
          </div>
          <button
            type="submit"
            className="button button--dark button--margin-right"
          >
            Search
          </button>
          <button onClick={handleReset} className="button button--light">
            Reset
          </button>
        </form>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <ProjectColumns projects={projects} designers={designers} />
      )}
    </React.Fragment>
  );
}
