import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchProjects, searchProjects } from "../../actions";
import { Link } from "react-router-dom";
import ProjectColumns from "../project/ProjectColumns";
import getStatusKeys from "../../utils/getStatusKeys";
import Loading from "../Loading";

export default function ProjectList() {
  const projects = useSelector((state) => state.user.projects);
  const customerName = useSelector((state) => state.auth.user.name);
  const role = useSelector((state) => state.auth.user.role);
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const [priority, setPriority] = useState("");
  const [data, setData] = useState({
    order: "asc",
    status: "",
  });

  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const isCustomer = role === "customer" || role === "subscriber";

  useEffect(() => {
    const loadAssets = async () => {
      await dispatch(fetchProjects(data));

      setLoading(false);
    };

    loadAssets();
  }, []);

  useEffect(() => {
    const loadAssets = async () => {
      setLoading(true);

      await dispatch(fetchProjects({ ...data, status, priority }));

      setLoading(false);
    };

    loadAssets();
  }, [status, priority]);

  const renderNoProjects = () => {
    return (
      <div className="project-index project-index--no-projects container">
        <div className="project-card project-card--new-campaign">
          <div className="project-card__header">
            <h2 className="project-card__title">No Projects</h2>
          </div>
          <div className="project-card__info">
            <p>You don’t have any active projects!</p>
            <div className="project-card__footnotes">
              <p className="project-card__footnote">
                <a href="/">View Design Lookbook</a>
              </p>
              <p className="project-card__footnote">
                Issues with this project? <a href="/">Contact Support</a>
              </p>
            </div>
          </div>
          <div className="project-card__new-campaign">
            <Link to="/new-project" className="button button--dark push-left">
              New Project
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const handleSearch = async (event) => {
		event.preventDefault();
		if (search === "") {
			handleReset(event);
		}
    try {
      setLoading(true);
      if (!search) {
        await dispatch(
          fetchProjects({
            order: "asc",
            status: "",
            assigned: undefined,
          })
        );
        return;
      }
      await dispatch(searchProjects(search));
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const handleReset = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      await dispatch(
        fetchProjects({
          order: "asc",
          status: "",
        })
      );
      setSearch("");
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <header className="container">
        <div className="dashboard-header">
          <h1 className="dashboard-header__client-name">
            <span>{customerName && customerName}</span>
          </h1>
          {isCustomer && (
            <Link to="/new-project" className="button button--dark push-left">
              New Project
            </Link>
          )}
        </div>
      </header>

      <div className="dashboard-filter container">

        <div className="dashboard-filter__item" style={{ display: "none" }}>
          <label htmlFor="status" className="dashboard-filter__title">
            Status
          </label>
          <select
            className="button button--filter button--dark"
            id="status"
            value={status}
            onChange={(event) => setStatus(event.target.value)}
          >
            <option value="">View All</option>
            {getStatusKeys().map((status) => (
              <option key={status.value} value={status.value}>
                {status.display}
              </option>
            ))}
          </select>
        </div>
        <form onSubmit={handleSearch} className="dashboard-filter__search">
          <div className="dashboard-filter__search__bar">
            <label htmlFor="search" className="dashboard-filter__title">
              Search Projects
            </label>
            <input
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              className="dashboard-filter__search__input"
            ></input>
          </div>
          <button
            type="submit"
            className="button button--dark button--margin-right"
          >
            Search
          </button>
          <button onClick={handleReset} className="button button--light">
            Reset
          </button>
        </form>
      </div>

      {loading ? (
        <Loading />
      ) : projects.length ? (
        <ProjectColumns projects={projects} />
      ) : (
        renderNoProjects()
      )}
    </React.Fragment>
  );
}
