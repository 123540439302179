import React from "react";
import {Link} from "react-router-dom";

function ThankRegistration() {
  return (
      <header className="container">
          <div className="dashboard-header dashboard-header--account">
              <h1 className="dashboard-header__client-name">
                  <span>Thank You For Registering!</span>
              </h1>
              <Link to="/new-project" className="button button--dark push-left">
                  Start Your Project
              </Link>
          </div>
      </header>
  );
}

export default ThankRegistration;
