import { combineReducers } from "redux";
import adminProjectsReducers from "./adminProjectsReducers";
import authReducers from "./authReducers";
import projectsReducers from "./projectsReducers";

export default combineReducers({
  admin: adminProjectsReducers,
  auth: authReducers,
  user: projectsReducers,
});
