import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { login } from "../../actions";

function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.user) {
      history.push("/projects");
    }
  }, [auth]);

  const submit = async (e) => {
    e.preventDefault();

    const user = {
      email,
      password,
    };

    try {
      const response = await axios.post("/users/login", user);
      await dispatch(login(response.data));
      history.push("/projects");
    } catch (error) {
      setError(error.response.data.msg);
    }
  };

  return (
    <React.Fragment>
      <header className="container">
        <div className="dashboard-header dashboard-header--account">
          <h1 className="dashboard-header__client-name">
            <span>Login</span>
          </h1>
          <Link to="/register" className="button button--dark push-left">
            Create Account Instead
          </Link>
        </div>
      </header>
      <div className="container">
        <form onSubmit={submit} className="form form--new-project block-padding">
          <label htmlFor="email">Email</label>
          <input
              id="email"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
          />
          <label htmlFor="password">Password</label>
          <input
              id="password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
          />
          <button className="button button--dark">Submit</button>
          <br></br>
          <br></br>
          {error && <p>Error: {error}</p>}
          <br></br>
          <Link to="/recovery">Forgot Password?</Link>
        </form>
      </div>
    </React.Fragment>
  );
}

export default Login;
