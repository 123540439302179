import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import axios from "axios";

export default function Register() {
  const history = useHistory();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [password, setPassword] = useState();
  const [passwordCheck, setPasswordCheck] = useState();
  const [first, setFirst] = useState();
  const [last, setLast] = useState();
  const [error, setError] = useState();

  const submit = async (e) => {
    e.preventDefault();

    const newUser = {
      email,
      password,
      passwordCheck,
      firstName: first,
      lastName: last,
      phone,
    };

    try {
      await axios.post("/users/register", newUser);
      history.push("/registered");
    } catch (error) {
      setError(error.response.data.msg);
    }
  };

  return (
    <React.Fragment>
      <header className="container">
        <div className="dashboard-header dashboard-header--account">
          <h1 className="dashboard-header__client-name">
            <span>Register</span>
          </h1>
          <Link to="/login" className="button button--dark push-left">
            Login Instead
          </Link>
        </div>
      </header>
      {error && <p>Error: {error}</p>}
      <div className="container">
        <form onSubmit={submit} className="form form--new-project block-padding">
          <label htmlFor="email">Email</label>
          <input
              id="email"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
          />

          <label htmlFor="phone">Phone Number</label>
          <PhoneInput
              placeholder="Enter phone number (optional)"
              id="phone"
              defaultCountry="US"
              value={phone}
              onChange={(e) => setPhone(e)}
          />

          <label htmlFor="password">Password</label>
          <input
              id="password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
          />

          <label htmlFor="verify-password">Verify Password</label>
          <input
              id="verify-password"
              type="password"
              onChange={(e) => setPasswordCheck(e.target.value)}
          />

          <label htmlFor="name">First Name</label>
          <input
              id="name"
              type="text"
              onChange={(e) => setFirst(e.target.value)}
          />

          <label htmlFor="last">Last Name</label>
          <input
              id="last"
              type="text"
              onChange={(e) => setLast(e.target.value)}
          />
          <p className="form__disclaimer">Disclaimer: Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
          <button className="button button--dark">Submit</button>
        </form>
      </div>
    </React.Fragment>
  );
}
