import React from "react";

export default function ProjectStatus({ currentStatus }) {
    let status = '';
    switch (currentStatus) {
        case "pending":
            status = "Pending";
            break;
        case "accepted":
            status = "Approved";
            break;
        case "inprogress":
            status = "In Progress";
            break;
        case "approval":
            status = "Needs Approval";
            break;
        case "revision":
            status = "Revisions Requested";
            break;
        case "canceled":
            status = "Cancelled";
            break;
        case "approved":
            status = "Approved";
            break;
        default:
            status = 'No Status';
            break;
    }
    return (
        <React.Fragment>{status}</React.Fragment>
    );
}
