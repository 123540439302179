import React from "react";
import "./styles/Input.css";

export default function Input({ message, setMessage, sendMessage }) {
  return (
    <React.Fragment>
        <h3 className="chat__new-message__title">New Message</h3>
        <form className="form-chat">
            <input
                className="input"
                type="text"
                placeholder="Type a message..."
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                onKeyPress={(event) =>
                    event.key === "Enter" ? sendMessage(event) : null
                }
            />
            <button className="chat__new-message__send button button--dark" onClick={(event) => sendMessage(event)}>
                Send
            </button>
        </form>
    </React.Fragment>
  );
}
