import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

function Recovery() {
  const history = useHistory();
  const [email, setEmail] = useState();
  const [error, setError] = useState();
  const [message, setMessage] = useState();
  const [sending, setSending] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    setSending(true);
    try {
      const response = await axios.post("/users/recovery", { email });

      setMessage("Password reset email sent! You will now be redirected.");

      setTimeout(function () {
        history.push("/");
      }, 3000);
    } catch (error) {
      setError(error.response.data.msg);
      setSending(false);
    }
  };

  return (
    <React.Fragment>
      <header className="container">
        <div className="dashboard-header dashboard-header--account">
          <h1 className="dashboard-header__client-name">
            <span>Password Recovery</span>
          </h1>
          <Link to="/login" className="button button--dark push-left">
            Login
          </Link>
        </div>
      </header>
      <form onSubmit={submit} className="form form--new-project block-padding">
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <button disabled={sending} className="button button--dark">
          Submit
        </button>
        {message && <p>{message}</p>}
        {error && <p>{error}. Pease try again.</p>}
      </form>
    </React.Fragment>
  );
}

export default Recovery;
