import React, { useState } from "react";
import Modal from "../Modal";
import { useSelector, useDispatch } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { updateProject } from "../../actions";
import axios from "axios";

export default function AssignDesigner({ project, button = true, designers }) {
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(false);
  const users = useSelector((state) => state.admin.designers);
  const dispatch = useDispatch();

  const [tables, setTables] = useState({
    available: {
      id: "available",
      content: users.filter((user) => {
        // if (!project.designer.includes(user._id)) return user; Leaving this code as reference it was buggy at first
        let found = true;
        project.designer.forEach((designer) => {
          if (designer._id === user._id) {
            found = false;
          }
        });
        return found;
      }),
    },
    assigned: {
      id: "assigned",
      content: project.designer.map((designer) => {
        const designerInfo = users.find((user) => user._id === designer._id);
        return designerInfo;
      }),
    },
  });

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const renderModal = () => {
    return (
      <Modal onDismiss={closeModal}>
        <div>
          <h2 className="project-view__title">Add a designer to the list</h2>
          {assignForm()}
        </div>
      </Modal>
    );
  };

  const onDragEnd = (event) => {
    const { destination, source, draggableId } = event;
    setError(false);

    if (!destination) return;

    if (destination.droppableId === source.droppableId) return;

    if (destination.droppableId === "assigned") {
      if (tables.assigned.content.length > 2) {
        setError(true);
        return;
      }
      const newavailableContent = tables.available.content.filter(
        (designer) => designer._id !== draggableId
      );
      const newAssignedContent = [...tables.assigned.content];
      const designerInfo = users.find((user) => user._id === draggableId);
      newAssignedContent.push(designerInfo);
      setTables({
        ...tables,
        ...(tables.available.content = newavailableContent),
        ...(tables.assigned.content = newAssignedContent),
      });
      return;
    }

    if (destination.droppableId === "available") {
      const newavailableContent = [...tables.available.content];
      const designerInfo = users.find((user) => user._id === draggableId);
      newavailableContent.push(designerInfo);

      const newAssignedContent = tables.assigned.content.filter(
        (designer) => designer._id !== draggableId
      );

      setTables({
        ...tables,
        ...(tables.available.content = newavailableContent),
        ...(tables.assigned.content = newAssignedContent),
      });
      return;
    }
    return;
  };

  const getavailableDesigner = () => {
    return (
      <ul>
        {tables.available.content.map((designer, index) => {
          return (
            <Draggable
              key={`designer-${designer._id}`}
              draggableId={designer._id}
              index={index}
            >
              {(provided) => (
                <li
                  {...provided.dragHandleProps}
                  {...provided.draggableProps}
                  ref={provided.innerRef}
                >
                  {designer.firstName} {designer.lastName}
                </li>
              )}
            </Draggable>
          );
        })}
      </ul>
    );
  };

  const getAssignedDesigner = () => {
    return (
      <ul>
        {tables.assigned.content.map((designer, index) => {
          return (
            <Draggable
              key={`designer-${designer._id}`}
              draggableId={designer._id}
              index={index}
            >
              {(provided) => (
                <li
                  {...provided.dragHandleProps}
                  {...provided.draggableProps}
                  ref={provided.innerRef}
                >
                  {designer.firstName} {designer.lastName}
                </li>
              )}
            </Draggable>
          );
        })}
      </ul>
    );
  };

  const assignForm = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        {error && (
          <p style={{ color: "red", fontSize: "10px" }}>
            No more than 3 designers allowed.
          </p>
        )}
        <div className="project-tools__grid">
          <div className="project-tools__column">
            <span className="project-tools__item">Available Designers</span>
            <Droppable droppableId="available">
              {(provided) => (
                <div
                  style={{
                    padding: "5px",
                    backgroundColor: "#f1faee",
                    margin: "1px",
                  }}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {getavailableDesigner()}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>

          <div className="project-tools__column">
            <span className="project-tools__item">Assigned Designers</span>
            <Droppable droppableId="assigned">
              {(provided) => (
                <div
                  style={{
                    padding: "5px",
                    backgroundColor: "#f1faee",
                    margin: "1px",
                  }}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {getAssignedDesigner()}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
        <div>
          <button onClick={onSave} className="button button--dark">Save</button>
        </div>
      </DragDropContext>
    );
  };

  const onSave = async () => {
    try {
      const designersIds = tables.assigned.content.map(
        (designer) => designer._id
      );
      const response = await axios.put("/projects/assign/mass", {
        project_id: project._id,
        designer_array: designersIds,
      });
      await dispatch(updateProject(response.data.project));
			closeModal();
			window.location.reload(false);
    } catch (error) {
      console.log(error);
    }
	};
	
	const renderDesignerLabel = () => {
		if (project.designer.length > 0) {
			return "Change/Assign New Designer"
		} else {
			return "Assign Designer"
		}
	}

  return (
    <React.Fragment>
      {button ? (
        <button
          className="button button--tag push-left button--dark"
          onClick={openModal}
        >
          Assign Designer
        </button>
      ) : (
        <button onClick={openModal} className="project-tools__item">
          <i className="fad fa-user-plus"></i>{renderDesignerLabel()}
        </button>
      )}

      {modal && renderModal()}
    </React.Fragment>
  );
}
