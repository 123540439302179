import React, { useState, useEffect } from "react";
import axios from "axios";
import io from "socket.io-client";
import { useSelector } from "react-redux";
import Loading from "../Loading";

import "./styles/Chat.css";
import InfoBar from "./InfoBar";
import Input from "./Input";
import Messages from "./Messages";

let socket;

function Chat({ location, historyMessages, projectId, userName }) {
  const name = useSelector((state) => state.auth.user.name);
  const userId = useSelector((state) => state.auth.user.id);
  const [room, setRoom] = useState(projectId ? projectId : "");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState(
    historyMessages ? historyMessages : []
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const env = process.env.NODE_ENV;

  const ENDPOINT =
    env === "production" ? window.location.hostname : "localhost:5000";

  useEffect(() => {
    socket = io(ENDPOINT);

    socket.emit("join", { userId, name, room }, () => {});

    return () => {
      socket.emit("disconnect");

      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    socket.on("message", (message) => {
      setMessages([...messages, message]);
    });
  }, [messages]);

  const sendMessage = (event) => {
    event.preventDefault();

    if (message) {
      socket.emit("sendMessage", { userId, message }, () => setMessage(""));
		}
		
		if (selectedFile) {
			sendUploadMessage();
		}
  };

  const sendUploadMessage = async () => {
    setIsUploading(true);
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      const response = await axios.put("/projects/chatupload", formData);

      const fileURL = response.data.data.file.location;

      socket.emit("sendMessage", { userId, message: fileURL }, () =>
        setMessage("")
      );
      setSelectedFile(null);
    }
    setIsUploading(false);
  };

  const handleOnChage = (e) => {
    if (e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  return (
    <div className="container-chat">
      <Messages messages={messages} name={name} />
      <Input
        message={message}
        setMessage={setMessage}
        sendMessage={sendMessage}
      />
      <div>
        {isUploading ? (
          <Loading />
        ) : (
          <React.Fragment>
            <label htmlFor="zip" className="button button--light">
              Upload File
            </label>
            <input
              type="file"
              id="zip"
              name="zip"
              accept=".zip,.rar,.7zip,.jpg,.jpeg,.png"
              className="upload-block"
              style={{ display: "none" }}
              onChange={handleOnChage}
            ></input>
            {selectedFile && <p style={{marginTop: 0, marginBottom: '1rem'}} className="project-view__sidebar__details__description">File Name: {selectedFile.name}</p>}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default Chat;
