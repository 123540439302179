import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import Chat from "../chat/Chat";
import AssignDesigner from "../admin/AssignDesigner";
import UploadDesign from "../project/UploadDesign";
import ApproveDesign from "../project/ApproveDesign";
import RequestEdits from "../project/RequestEdits";
import totalHours from "../../utils/totalHours";
import Loading from "../Loading";

// Test
import { verifyToken, fetchDesigners } from "../../actions";
import ProjectStatus from "../project/ProjectStatus";

export default function Project() {
  const { id } = useParams();
  const user = useSelector((state) => state.auth.user);
  const designers = useSelector((state) => state.admin.designers);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  // ================================
  // States: images and selectedImage
  // ================================
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const dispatch = useDispatch();
  const isCustomer = user.role === "customer" || user.role === "subscriber";

  const {
    owner_id,
    status,
    name,
    _id,
    chat,
    instructions,
    hours,
    assets,
    thumbnail,
    previewImgs,
		reference
  } = data;

  useEffect(() => {
    const getProject = async () => {
      try {
        const project = await axios(`/projects/${id}`);
        if (user.role === "admin") {
          await dispatch(fetchDesigners());
        }
        setData(project.data.data.project);
        // Push main image to images
        if (project.data.data.project.thumbnail) {
          setSelectedImage(project.data.data.project.thumbnail);
          setImages((oldImages) => [
            ...oldImages,
            project.data.data.project.thumbnail,
          ]);
        }
				if (!project.data.data.project.thumbnail && project.data.data.project.previewImgs[0]) {
					setSelectedImage(project.data.data.project.previewImgs[0]);
				}
        setImages((oldImages) => [
          ...oldImages,
          ...project.data.data.project.previewImgs,
        ]);
      } catch (error) {
        setData(null);
      } finally {
        setLoading(false);
      }
    };
    getProject();
  }, []);

  // =====================================
  // IMAGE IS RE_RENDER WHEN STATE CHANGES
  // =====================================
  useEffect(() => {
    renderThumbnail();
  }, [selectedImage]);

  const getProject = async () => {
    try {
      const project = await axios(`/projects/${id}`);
      setData(project.data.data.project);
    } catch (error) {
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const acceptProject = async () => {
    try {
      setIsPaymentLoading(true);
      await axios.put(`/projects/accept`, { project_id: id });
      await getProject();
      setIsPaymentLoading(false);
    } catch (error) {
      if (error.response.status === 402) {
        setErrorMessage(JSON.parse(error.request.response).msg);
      }
      if (error.response.status === 500) {
        setErrorMessage(JSON.parse(error.request.response).error);
      }
      setIsPaymentLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // ============================
  // RENDER MAIN IMAGE HERE
  // ============================
  const renderThumbnail = () => {
    return selectedImage ? (
      <div className="project-view__design__view__main margin-bottom-med ">
        <img src={selectedImage.location} />
      </div>
    ) : (
      <p className="project-view__design__text">No design uploaded.</p>
    );
  };

  // ============================
  // RENDER REFERENCES
  // ============================
	const renderReferences = () => {
		if (Array.isArray(reference)) {
			if (reference.length) {
				const referencesList = reference.map(url => <li style={{marginBottom: '1rem'}}><a href={url} target="_blank">{url}</a></li>)

				return (
					<div>
						<h2 class="project-view__title">Reference Links</h2>
						<ul style={{padding: '0'}}>
							{referencesList}
						</ul>
					</div>
				)
			}
			}
	}

  // ============================
  // PREVIEW IMAGES RENDERED HERE
  // ============================
  const renderPreviews = () => {
    return (
      <div className="project-view__design__thumbnails">
        {images.map((img) => {
          return (
            <img
              key={img.key}
              src={img.location}
              onClick={() => setSelectedImage(img)}
              className={`project-view__design__thumbnail ${
                selectedImage.key === img.key &&
                "project-view__design__thumbnail--active"
              }`}
            />
          );
        })}
      </div>
    );
  };

  const renderApprovalBtns = () => {
    if (
      (user.role === "customer" ||
        user.role === "subscriber" ||
        user.role === "admin") &&
      status === "approval"
    ) {
      return (
        <React.Fragment>
          <ApproveDesign project={data} render={getProject} />
          <RequestEdits project={data} render={getProject} />
        </React.Fragment>
      );
    }
  };

  const renderUploadDesignBtn = () => {
    const hasRole = user.role === "designer" || user.role === "admin";
    const hasStatus = status === "inprogress" || status === "revision";
    if (hasRole && hasStatus) {
      return (
        <React.Fragment>
          <UploadDesign render={getProject} id={_id} />
        </React.Fragment>
      );
    }
  };

  const barStyle = (hours) => {
    return {
      width: hours * 10 + "%",
      backgroundColor: hours > 5 ? "red" : "green",
    };
  };

  const renderProject = () => {
    if (loading) return <Loading />;

    if (!data) return "Project not found!";

    return (
      <React.Fragment>
        <header className="container">
          <div className="dashboard-header dashboard-header--project">
            <h1 className="dashboard-header__client-name">
              <Link to="/projects" className="text-button">
                <i className="far fa-angle-left"></i> Back to Projects
              </Link>
              <br></br>
              <span>{name}</span>
            </h1>
            <div className="dashboard-header__progress-bar">
              <span className="dashboard-header__progress-bar__title text-button">
                Project Progress:
              </span>
              <div
                className="dashboard-header__progress-bar__bar"
                aria-label="50%"
              >
                <div
                  className="dashboard-header__progress-bar__bar__inner"
                  style={barStyle(totalHours(hours))}
                ></div>
              </div>
              <span className="dashboard-header__progress-bar__avg text-button">
                (Avg. Design Time)
              </span>
            </div>
          </div>
        </header>
        <div className="project-view project-view--in-progress container">
          <div className="project-view__sidebar">
            <div className="project-view__sidebar__details block-padding">
              <h2 className="project-view__title">
                <span>Project Details</span>
                <span className="project-card__status push-left tag in-progress">
                  <ProjectStatus currentStatus={status} />
                </span>
              </h2>
              <p className="project-view__sidebar__details__description">
                {instructions}
              </p>
              <br />
							{renderReferences()}
              <div>
                {!isCustomer && (
                  <a
                    href={data.ownerAssets.location}
                    className="button button--dark push-left"
                  >
                    Download Assets
                  </a>
                )}
              </div>
            </div>

            {user.role === "admin" && (
              <div className="project-view__sidebar__tools block-padding">
                <h2 className="project-view__title">
                  <span>Project Manager Tools</span>
                </h2>
                {isPaymentLoading ? (
                  <Loading />
                ) : (
                  <React.Fragment>
                    {errorMessage && (
                      <p style={{ color: "red" }}>{errorMessage}</p>
                    )}
                    <div className="project-tools">
                      {status !== "pending" && (
                        <AssignDesigner
                          project={data}
                          designers={designers}
                          button={false}
                        />
                      )}
                      {status === "pending" && (
                        <button
                          onClick={acceptProject}
                          className="project-tools__item"
                        >
                          <i className="fad fa-check-circle"></i>Accept Project
                        </button>
                      )}
                      <a
                        className="project-tools__item"
                        href={`/projects/${id}/edit`}
                      >
                        <i className="fad fa-clock"></i>Update Designer Hours (
                        {totalHours(hours)})
                      </a>
                      <a
                        className="project-tools__item"
                        href={`/projects/${id}/edit`}
                      >
                        <i className="fad fa-edit"></i>Edit Project Details
                      </a>
                    </div>
                  </React.Fragment>
                )}
              </div>
            )}
            <div className="project-view__sidebar__chat block-padding">
              <h2 className="project-view__title">Chat</h2>
              <div className="chat">
                <Chat
                  historyMessages={chat}
                  projectId={_id}
                  userName={owner_id.firstName}
                />
              </div>
            </div>
          </div>
          <div className="project-view__design">
            <div className="project-view__approval">
              {renderApprovalBtns()}
            </div>
            <div className="project-view__design__view block-padding">
              <h2 className="project-view__title">
                <span>Design</span>
                {renderUploadDesignBtn()}
                {assets && status === "approved" && (
                  <a href={assets.location} className="button button--dark">
                    Download Design
                  </a>
                )}
              </h2>
              {renderThumbnail()}
              {previewImgs.length > 0 && renderPreviews()}
            </div>
            <div className="project-view__design__thumbnails"></div>
            <div className="project-view__design__options"></div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderDesigners = () => {
    if (data.designer.length === 0) return "No Designer Assign Yet.";
    const designersList = data.designer.map((designer) => (
      <li key={designer._id}>
        {designer.firstName} {designer.lastName}
      </li>
    ));

    return <ul>{designersList}</ul>;
  };

  return <div>{renderProject()}</div>;
}
