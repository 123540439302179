import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { fetchProjects } from "../../actions";
import Modal from "../Modal";

function RequestPackaging() {
  const projects = useSelector((state) => state.user.projects);
  const [project, setProject] = useState("No Project");
  const [message, setMessage] = useState("");
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadProjects = async () => {
      await dispatch(
        fetchProjects({
          order: "asc",
          status: "",
        })
      );
    };

    loadProjects();
  }, []);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const handleSubmit = async () => {
    try {
      const response = axios.put("/projects/request", {
        project,
        message,
      });
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };

  const renderModal = () => {
    return (
      <Modal onDismiss={closeModal}>
        <h2 className="modal__title">Request Packaging</h2>
        <div className="project-view project-view--new-project container">
          <div className="form form--packaging">
            <label htmlFor="project">Select Project</label>
            <div className="select">
              <i className="fad fa-angle-down"></i>
              <select
                name="project"
                id="project"
                value={project}
                onChange={(e) => setProject(e.target.value)}
              >
                <option value="No Project">No Project</option>
                {projects.map((project) => (
                  <option key={project.name} value={project.name}>
                    {project.name}
                  </option>
                ))}
              </select>
            </div>
            <label htmlFor="instructions">Additional Comments</label>
            <textarea
              id="instructions"
              placeholder="Please be as clear as you can, and give references if possible!"
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
            <button
              onClick={handleSubmit}
              type="submit"
              className="button button--dark"
            >
              Request Packaging
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      <a onClick={openModal}>Packaging</a>
      {modal && renderModal()}
    </React.Fragment>
  );
}

export default RequestPackaging;
