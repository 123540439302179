import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AuthOptions from "../auth/AuthOptions";
import ContactSupport from "../user/ContactSupport";
import RequestPackaging from "../project/RequestPackaging";

export default function Header() {
  const history = useHistory(); // TODO since we need to check logged in status on menu items as well as the AuthOptions components, let's combine those two into one component for simplicity / less code
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.auth.user) !== undefined;
  const login = () => history.push("/login");
  const button = loggedIn ? (
    <a href="/account" className="button button--light">
      My Account
    </a>
  ) : (
    <button className="button button--light" onClick={login}>
      My Account
    </button>
  );

  return (
    <nav className="nav">
      <div className="nav__top">
        <div className="nav__top__inner container">
          <AuthOptions />
        </div>
      </div>
      <ul className="nav__menu">
        <div className="nav__menu__inner container">
          <li className="active">
            <Link to="/projects">Projects</Link>
          </li>
          <li>
            <RequestPackaging />
          </li>
          <li>
            <a href="https://topshelfdesign.co/projects" target="_blank">Lookbook</a>
          </li>
          <li className="mobile-break"></li>
          <li className="nav__menu__has-button push-left">{button}</li>
          <li className="nav__menu__has-button">
            <ContactSupport />
          </li>
        </div>
      </ul>
    </nav>
  );
}
