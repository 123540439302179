import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { verifyToken } from "../../actions";
import ProjectCard from "../ProjectCard";

export default function ProjectColumns({ projects, designers = [] }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.auth.user);
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const projectScroller = useRef();

  useEffect(() => {
    const getProject = async () => {
      try {
        await dispatch(verifyToken());
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };
    getProject();
  }, []);

  const scrollingTest = (event) => {
    // console.log("One: ", event.target);
    // console.log("Two: ", projectScroller.current);
    // console.log("One: ", event.target.offsetTop);
    // console.log("Two: ", projectScroller.current.offsetTop);
    // function onScroll() {
    //   let currentPosition =
    //     projectScroller && projectScroller.current.offsetTop; // or use document.documentElement.scrollTop;
    //   console.log(currentPosition);
    //   if (currentPosition > scrollTop) {
    //     // downscroll code
    //     console.log("scrolling false");
    //     setScrolling(false);
    //   } else {
    //     // upscroll code
    //     console.log("scrolling true");
    //     setScrolling(true);
    //   }
    //   setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    // }

    // // onScroll();
    // window.addEventListener("scroll", onScroll);
  };

  const sortProjects = () => {
    const sortedProjects = {
      pending: [],
      accepted: [],
      inProgress: [],
      approval: [],
      revision: [],
      approved: [],
      canceled: [],
    };

    projects.forEach((project) => {
      switch (project.status) {
        case "pending":
          sortedProjects.pending.push(project);
          break;
        case "accepted":
          sortedProjects.accepted.push(project);
          break;
        case "inprogress":
          sortedProjects.inProgress.push(project);
          break;
        case "approval":
          sortedProjects.approval.push(project);
          break;
        case "revision":
          sortedProjects.revision.push(project);
          break;
        case "canceled":
          sortedProjects.canceled.push(project);
          break;
        case "approved":
          sortedProjects.approved.push(project);
          break;
        default:
          break;
      }
    });

    return sortedProjects;
  };

  const displayProjects = () => {
    const sortedProjects = sortProjects();

    return (
      <div className="project-index container">
        <div className="project-index__header" ref={projectScroller}>
          {user.role !== "designer" && sortedProjects.pending.length > 0 && (
            <div className="project-index__column">
              <h2 className="project-index__title">Pending</h2>
            </div>
          )}
          {user.role !== "designer" && sortedProjects.accepted.length > 0 && (
            <div className="project-index__column">
              <h2 className="project-index__title">Accepted</h2>
            </div>
          )}
          {sortedProjects.inProgress.length > 0 && (
            <div className="project-index__column">
              <h2 className="project-index__title">In Progress</h2>
            </div>
          )}
          {sortedProjects.approval.length > 0 && (
            <div className="project-index__column">
              <h2 className="project-index__title">Needs Approval</h2>
            </div>
          )}
          {sortedProjects.revision.length > 0 && (
            <div className="project-index__column">
              <h2 className="project-index__title">Revision Requested</h2>
            </div>
          )}
          {sortedProjects.approved.length > 0 && (
            <div className="project-index__column">
              <h2 className="project-index__title">Approved</h2>
            </div>
          )}
          {sortedProjects.canceled.length > 0 && (
            <div className="project-index__column">
              <h2 className="project-index__title">Cancelled</h2>
            </div>
          )}
        </div>
        <div className="project-index__projects">
          {user.role !== "designer" && sortedProjects.pending.length > 0 && (
            <div className="project-index__column">
              <h2 className="project-index__title project-index__title--mobile">Pending</h2>
              {sortedProjects.pending.map((project) => (
                <ProjectCard
                  key={project._id}
                  project={project}
                  designers={designers}
                />
              ))}
            </div>
          )}
          {user.role !== "designer" && sortedProjects.accepted.length > 0 && (
            <div className="project-index__column">
              <h2 className="project-index__title project-index__title--mobile">Accepted</h2>
              {sortedProjects.accepted.map((project) => (
                <ProjectCard
                  key={project._id}
                  project={project}
                  designers={designers}
                />
              ))}
            </div>
          )}
          {sortedProjects.inProgress.length > 0 && (
            <div className="project-index__column">
              <h2 className="project-index__title project-index__title--mobile">In Progress</h2>
              {sortedProjects.inProgress.map((project) => (
                <ProjectCard
                  key={project._id}
                  project={project}
                  designers={designers}
                />
              ))}
            </div>
          )}
          {sortedProjects.approval.length > 0 && (
            <div className="project-index__column">
              <h2 className="project-index__title project-index__title--mobile">Needs Approval</h2>
              {sortedProjects.approval.map((project) => (
                <ProjectCard
                  key={project._id}
                  project={project}
                  designers={designers}
                />
              ))}
            </div>
          )}
          {sortedProjects.revision.length > 0 && (
            <div className="project-index__column">
              <h2 className="project-index__title project-index__title--mobile">Revision Requested</h2>
              {sortedProjects.revision.map((project) => (
                <ProjectCard
                  key={project._id}
                  project={project}
                  designers={designers}
                />
              ))}
            </div>
          )}
          {sortedProjects.approved.length > 0 && (
            <div className="project-index__column">
              <h2 className="project-index__title project-index__title--mobile">Approved</h2>
              {sortedProjects.approved.map((project) => (
                <ProjectCard
                  key={project._id}
                  project={project}
                  designers={designers}
                />
              ))}
            </div>
          )}
          {sortedProjects.canceled.length > 0 && (
            <div className="project-index__column">
              <h2 className="project-index__title project-index__title--mobile">Cancelled</h2>
              {sortedProjects.canceled.map((project) => (
                <ProjectCard
                  key={project._id}
                  project={project}
                  designers={designers}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  return <React.Fragment>{!loading && displayProjects()}</React.Fragment>;
}
