import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AssignDesigner from "./admin/AssignDesigner";
import totalHours from "../utils/totalHours";
import ProjectPriority from "./project/ProjectPriority";
import getStatus from "../utils/getStatus";

export default function ProjectCard(props) {
  const {
    owner_id,
    name,
    status,
    hours,
    _id,
    instructions,
    thumbnail,
    assets,
    priority,
  } = props.project;
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      setLoading(false);
    }
  });

  // TODO refactor this so there's one component that manages the class names and display names of all the statuses. right now this function + the ProjectStatus.js component handle class names/display names respectively

  const cardStatus = () => {
    return (
			<h3
			className={`project-card__priority push-left tag project-card__priority--${priority}`}
		>
			<ProjectPriority currentPriority={priority} />
		</h3>
		);
  };

  const customerCancelled = () => {
    return (
      <div className="project-card__info">
        <h4 className="project-card__link project-card__link--refunded">
          <i className="far fa-ban"></i>Refunded
        </h4>
        <div className="project-card__footnotes">
          <p className="project-card__footnote">
            Issues with this project? <a href="/">Contact Support</a>
          </p>
        </div>
      </div>
    );
  };

  const customerPending = () => {
    return (
      <div className="project-card__info">
        <p>{instructions}</p>
        <div className="project-card__footnotes">
          <p className="project-card__footnote">
            <a href="/">View Design Lookbook</a>
          </p>
          <p className="project-card__footnote">
            Issues with this project? <a href="/">Contact Support</a>
          </p>
        </div>
      </div>
    );
  };

  const customerStandard = () => {
    return (
      <React.Fragment>
        <div className="project-card__info">
          {assets && status === "approved" && (
            <h4 className="project-card__link project-card__link--download">
              <div href={assets.location}>
                <i className="fad fa-download"></i>Download Project Files
              </div>
            </h4>
          )}
          <h4 className="project-card__link project-card__link--packaging">
            <div href="/">
              <i className="fad fa-box-open"></i>Request Packaging
            </div>
          </h4>
        </div>
        {props.project.thumbnail && (
          <div className="project-card__image">
            <img
              src={props.project.thumbnail.location}
              alt="Wookie Exotics Label"
            />
          </div>
        )}
      </React.Fragment>
    );
  };

  const customerContent = () => {
    let content;

    if (status === "pending") {
      content = customerPending();
    } else if (status === "canceled") {
      content = customerCancelled();
    } else {
      content = customerStandard();
    }
    return (
      <React.Fragment>
        <a href="/projects/{_id}" className="project-card__header">
          <h2 className="project-card__title">{name}</h2>
          <h3
            className={`project-card__priority push-left tag project-card__priority--${priority}`}
          >
            <ProjectPriority currentPriority={priority} />
          </h3>
        </a>
        {content}
      </React.Fragment>
    );
  };

  const adminContent = () => {
    return (
      <React.Fragment>
        <div className="project-card__header project-card__header--reduce-padding">
          <h2 className="project-card__title">
            <span>{name}</span>
          </h2>
          {cardStatus()}
        </div>
        <div className="project-card__info">
          <p className="project-card__client-name">
            {owner_id.firstName} {owner_id.lastName}
          </p>
          <h4 className="project-card__link project-card__link--chat">
            <span>
              <i className="fad fa-comments"></i>Open Project
            </span>
          </h4>
          <h4 className="project-card__link project-card__link--hours">
            <span>
              <i className="fad fa-clock"></i>Current Hours: {totalHours(hours)}
            </span>
          </h4>
        </div>
        {props.project.thumbnail && (
          <div className="project-card__image">
            <img
              src={props.project.thumbnail.location}
              alt="Wookie Exotics Label"
            />
          </div>
        )}
      </React.Fragment>
    );
  };

  const renderContent = () => {
    if (user.role === "admin" || user.role === "designer") {
      return adminContent();
    } else {
      return customerContent();
    }
  };

  return (
    <Link 
      className={`project-card project-card--${getStatus(
        status
      )} project-card--${_id}`}
			to={`/projects/${_id}`}
    >
      {!loading && renderContent()}
    </Link>
  );
}
