import React from "react";
import ProjectListAdmin from "../admin/ProjectListAdmin";
import UsersList from "../admin/UsersList";
import UserHeader from "../user/UserHeader";

export default function AdminPanel() {
  return (
    <>
      <UserHeader />
      <UsersList />
    </>
  );
}
