import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import UserHeader from "../user/UserHeader";
import ProfileMenu from "../user/ProfileMenu";
import Loading from "../Loading";
import { verifyToken } from "../../actions";

export default function Account() {
  const user = useSelector((state) => state.auth.user);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.phone);
  const [error, setError] = useState();
  const [isSending, setIsSending] = useState(false);
	const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newInfo = {
      firstName,
      lastName,
      email,
      phone,
    };

    if (user.email === email) {
      newInfo.email = undefined;
    }

    try {
      setIsSending(true);
      const response = await axios.put("/users/update", newInfo);
      await dispatch(verifyToken());
      window.location.reload();
    } catch (error) {
      setIsSending(false);
      setError(error.response.data.msg);
    }
  };

  return (
    <React.Fragment>
      <UserHeader />
      <div className="account container">
        <div className="account__view account__view--main block-padding">
          <h2 className="account__title">Profile</h2>
          <form className="form" onSubmit={handleSubmit}>
            <label htmlFor="first">First Name</label>
            <input
              type="text"
							onChange={(e) => setFirstName(e.target.value)}
							value={firstName}
            />
            <label htmlFor="last">Last Name</label>
            <input
              type="text"
							onChange={(e) => setLastName(e.target.value)}
							value={lastName}
            />
            <label htmlFor="phone">Phone</label>
            <PhoneInput
              placeholder={
                user.phone
                  ? `(${user.phone.substr(2, 3)}) ${user.phone.substr(
                      5,
                      3
                    )} - ${user.phone.substr(8, 4)}`
                  : "Add phone number (optional)"
              }
              id="phone"
              defaultCountry="US"
              value={phone}
              onChange={(e) => setPhone(e)}
            />
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
							onChange={(e) => setEmail(e.target.value)}
							value={email}
            />
            <div>
              <button
                disabled={isSending}
                type="submit"
                className="button button--dark"
              >
                Save Changes
              </button>
              {isSending && <Loading />}
            </div>
            {error && <p>Error: {error}</p>}
          </form>
        </div>
        <ProfileMenu route="account" />
      </div>
    </React.Fragment>
  );
}
