import React from "react";
import ReactDOM from "react-dom";
// import "../scss/components/ModalStyles.scss";

function Modal(props) {
  return ReactDOM.createPortal(
    <div onClick={props.onDismiss} className="modal">
      <div
        onClick={(e) => e.stopPropagation()}
        className="modal__inner block-padding"
      >
        <div className="modal__top-bar">
          <button
            aria-label="Close"
            onClick={props.onDismiss}
						className="modal__close"
						style={{zIndex: "500"}}
          >
            <i className="modal__icon fas fa-window-close"></i>
          </button>
        </div>
        {props.children}
      </div>
    </div>,
    document.querySelector("#modal")
  );
}

export default Modal;
