import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import axios from "axios";
import { fetchDesigners } from "../../actions";
import getStatus from "../../utils/getStatus";
import Loading from "../Loading";
import Hour from "./Hour";
import ProjectStatus from "../pages/Project";
import DeleteProject from '../../components/project/DeleteProject';

function EditProject() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const designers = useSelector((state) => state.admin.designers);
  const [project, setProject] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [isNameModified, setIsNameModified] = useState(false);
  const [instructions, setInstructions] = useState("");
  const [isInstructionsModified, setIsInstructionsModified] = useState(false);
  const [priority, setPriority] = useState("");
  const [isPriorityModified, setIsPriorityModified] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [hours, setHours] = useState([]);
  const [thumbnail, setThumbnail] = useState(null);
  const [previews, setPreviews] = useState(null);
  const [isFileUrlModified, setIsFileUrlModified] = useState(null);
  const [newThumbnail, setNewThumbnail] = useState(null);
  const [newPreviews, setNewPreviews] = useState(null);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    const getProject = async () => {
      try {
        setIsLoading(true);
        await dispatch(fetchDesigners());
        const response = await axios(`/projects/${id}`);
        const project = response.data.data.project;
        setProject(project);
        setName(project.name);
        setInstructions(project.instructions);
        setPriority(project.priority);
        setThumbnail(project.thumbnail);
        setPreviews(project.previewImgs);
        setHours(project.hours);
				setFileUrl(project.assets.location);
      } catch (error) {
        console.log(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    getProject();
  }, []);

  const getProject = async () => {
    const response = await axios(`/projects/${id}`);
    const project = response.data.data.project;
    setProject(project);
    setName(project.name);
    setInstructions(project.instructions);
    setPriority(project.priority);
    setThumbnail(project.thumbnail);
    setPreviews(project.previewImgs);
    setHours(project.hours);
		setFileUrl(project.assets.location);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsSending(true);
      // Upload basic fields
      if (isNameModified || isInstructionsModified || isPriorityModified || isFileUrlModified) {
        let params = {};
        if (isNameModified) params.name = name;
        if (isInstructionsModified) params.instructions = instructions;
        if (isPriorityModified) params.priority = priority;
				if (isFileUrlModified) params.fileUrl = fileUrl;

        await axios.put(`projects/${id}/edit`, params);
      }

      // Upload Thumbnail
      if (newThumbnail) {
        const formThumbnail = new FormData();
        formThumbnail.append("id", id);
        formThumbnail.append("file", newThumbnail);
        await axios.post("/projects/upload/thumbnail", formThumbnail);
      }

      // Upload Previews
      if (newPreviews) {
        const formPreviews = new FormData();
        formPreviews.append("id", id);
        formPreviews.append("file", newPreviews);
        newPreviews.forEach((file) => {
          formPreviews.append("file", file);
        });
        await axios.post("/projects/upload/previews", formPreviews);
      }
      await getProject();
      setIsSending(false);
    } catch (error) {
      console.log(error.message);
      setIsSending(false);
    }
  };

  const renderOldPreviews = () => {
    return previews.map((image) => {
      return (
        <p className="form__instructions" key={image.key}>
          {image.key}
        </p>
      );
    });
  };

  const renderNewPreviews = () => {
    return newPreviews.map((image) => {
      return (
        <p className="form__instructions" key={image.name}>
          {image.name}
        </p>
      );
    });
  };

  const renderForm = () => {
    return (
      <div className="account__view account__view--main block-padding">
        <form className="form" onSubmit={handleSubmit}>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setIsNameModified(true);
            }}
          />
          <br/><br/>
					<label htmlFor="name">Design Url</label>
          <input
            type="text"
            id="url"
            value={fileUrl}
            onChange={(e) => {
              setFileUrl(e.target.value);
              setIsFileUrlModified(true);
            }}
          />
          <br/><br/>
          <label htmlFor="instructions">Instructions</label>
          <textarea
            id="instructions"
            value={instructions}
            onChange={(e) => {
              setInstructions(e.target.value);
              setIsInstructionsModified(true);
            }}
          />
          <br/><br/>
          <br/><br/>
          <label htmlFor="priority">Priority</label>
          <select
            id="priority"
            value={priority}
            onChange={(e) => {
              setPriority(e.target.value);
              setIsPriorityModified(true);
            }}
          >
            <option value="0">Low</option>
            <option value="1">Medium</option>
            <option value="2">High</option>
          </select>
          <br/><br/>
          <label htmlFor="thumbnail" className="button button--light">
            Change Thumbnail
          </label>
          <input
            type="file"
            id="thumbnail"
            name="thumbnail"
            accept="image/png, image/jpeg"
            style={{ display: "none" }}
            onChange={(e) => setNewThumbnail(e.target.files[0])}
          ></input>
          <span className="form__instructions form__instructions--label">
            {thumbnail && !newThumbnail ? thumbnail.key : ""}
            {newThumbnail ? newThumbnail.name : ""}
          </span>
          <br/><br/>
          <label htmlFor="previews" className="button button--light">
            + Upload Previews
          </label>
          <input
            type="file"
            id="previews"
            name="previews"
            accept="image/png, image/jpeg"
            multiple={true}
            style={{ display: "none" }}
            onChange={(e) => {
              setNewPreviews(Array.from(e.target.files));
            }}
          ></input>
          {previews && !newPreviews && renderOldPreviews()}
          {newPreviews && renderNewPreviews()}
          <br/><br/>
          {isSending ? (
            <Loading />
          ) : (
						<button className="button button--dark">Update</button>
          )}
          <br/><br/>
        </form>
				<div>
					<DeleteProject project={project} />
				</div>
      </div>
    );
  };

  const renderHoursForm = () => {
    const renderedHours = hours.map((hour) => (
      <Hour
        key={hour.id}
        designers={designers}
        hour={hour}
        id={id}
        reload={getProject}
      />
    ));

    return (
      <div
        className="account__sidebar block-padding"
      >
        <h2 className="project-view__title">Hours Log</h2>
        {hours.length > 0 ? (
          <ul>{renderedHours}</ul>
        ) : (
          <div>No Hours Available</div>
        )}
      </div>
    );
  };

  return (
    <div className="container">
      {isLoading ? (
        <Loading />
      ) : (
        <React.Fragment>
          <header className="container">
            <div className="dashboard-header dashboard-header--project">
              <h1 className="dashboard-header__client-name">
                <Link to={`/projects/${id}`} className="text-button">
                  <i className="far fa-angle-left"></i> Back to Project
                </Link>
                <br></br>
                <span>{project.name}</span>
              </h1>
              <div className="dashboard-header__progress-bar">
                <span className="dashboard-header__progress-bar__title text-button">
                  Project Progress:
                </span>
                <div
                  className="dashboard-header__progress-bar__bar"
                  aria-label="50%"
                >
                  <div className="dashboard-header__progress-bar__bar__inner"></div>
                </div>
                <span className="dashboard-header__progress-bar__avg text-button">
                  (Avg. Design Time)
                </span>
              </div>
            </div>
          </header>
          <div
            className="account account--full container"
          >
            {renderForm()} {renderHoursForm()}
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default EditProject;
