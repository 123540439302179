import React from "react";
import ReactEmoji from "react-emoji";
import moment from "moment";
import "./styles/Message.css";

export default function Message({ message: { user, text, sendAt }, name }) {
  let isSentByCurrentUser = false;

  const trimmedName = name.trim().toLowerCase();

  if (user === trimmedName) isSentByCurrentUser = true;

  const isUrl = (str) => {
    const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (regexp.test(str)) {
      return true;
    } else {
      return false;
    }
  };

  const isMessageUrl = isUrl(text);

  return isSentByCurrentUser ? (
    <div className="chat__message chat__message--customer">
      <h3 className="chat__message__username">{trimmedName}</h3>
      {isMessageUrl ? (
        <div className="chat__message__text">
          {!text.endsWith(".zip") && <a href={text}><img src={text} width="150"></img></a>}
          <a href={text} style={{ textDecoration: "underline" }}>
            Download {text.endsWith(".zip") && "Zip File"}
          </a>
        </div>
      ) : (
        <p className="chat__message__text">{ReactEmoji.emojify(text)}</p>
      )}
      <p style={{ display: "none", fontSize: "1rem" }}>
        {moment(sendAt).format("MMM Do YY, h:mm:ss a")}
      </p>
    </div>
  ) : (
    <div className="chat__message chat__message--designer">
      <h3 className="chat__message__username">{user}</h3>
      {isMessageUrl ? (
				<div className="chat__message__text">
				{!text.endsWith(".zip") && <a href={text}><img src={text} width="150"></img></a>}
				<a href={text} style={{ textDecoration: "underline" }}>
					Download {text.endsWith(".zip") && "Zip File"}
				</a>
			</div>
      ) : (
        <p className="chat__message__text">{ReactEmoji.emojify(text)}</p>
      )}
      <p style={{ display: "none", fontSize: "1rem" }}>
        {moment(sendAt).format("MMM Do YY, h:mm:ss a")}
      </p>
    </div>
  );
}
