import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchUsers } from "../../actions";
import SetRole from "./SetRole";
import ProfileMenu from "../user/ProfileMenu";

function UsersList() {
  const users = useSelector((state) => state.admin.users);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    role: "",
  });

  useEffect(() => {
    const getUsers = async () => {
      await dispatch(fetchUsers({ role: data.role }));
    };

    getUsers();
  });

  const displayUsers = () => {
    if (users.length > 0) {
      return users.map((user) => (
          <div className="downloads__item" key={user._id}>
              <h3 className="downloads__title">{user.firstName} {user.lastName}<br/><span className="form__instructions">{user.role[0].toUpperCase() + user.role.substring(1)}</span><br/><span className="form__instructions">{user.email}</span></h3>
              <h4 className="downloads__link">
                  <SetRole user={user} />
              </h4>
          </div>

      ));
    }
    return "No Users Available";
  };

  return (
    <div>
        <div className="dashboard-filter dashboard-filter--account container">
            <div className="dashboard-filter__item"><label htmlFor="order" className="dashboard-filter__title">Filter by Role</label>
                <select
                    id="role"
                    value={data.role}
                    onChange={(event) => setData({ ...data, role: event.target.value })}
                    className="button button--filter button--dark"
                >
                    <option value="">All</option>
                    {["admin", "designer", "customer", "subscriber"].map((role) => (
                        <option key={role} value={role}>
                            {role}
                        </option>
                    ))}
                </select>
            </div>
        </div>
        <div className="account container">
            <div className="account__view account__view--main block-padding">
                <div className="downloads">
                    {displayUsers()}
                </div>
            </div>
            <ProfileMenu route="admin" />
        </div>

    </div>
  );
}

export default UsersList;
