import React from "react";
import ReactLoading from "react-loading";

function Loading(props) {
  const { height = "auto", width = 100 } = props;

  const styles = {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div style={styles}>
      <ReactLoading type="bars" color="#0e7f12" height={height} width={width} />
    </div>
  );
}

export default Loading;
