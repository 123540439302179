import React from "react";
import ReactDOM from "react-dom";
import "./scss/style.scss";
import "@fortawesome/fontawesome-pro/css/all.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";

import reducers from "./reducers";

// Setup Redux store
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk))
);

// Set axios defaults
axios.defaults.baseURL = "/api/v1";
const token = localStorage.getItem("auth-token");
if (token) {
  store.dispatch({ type: "VERIFY_TOKEN" });
  axios.defaults.headers.common["x-auth-token"] = token;
}

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
