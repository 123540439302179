import React from "react";

export default function ProjectPriority({ currentPriority }) {
    let priority = '';
    switch (currentPriority) {
        case 2:
            priority = "High";
            break;
        case 1:
            priority = "Med";
            break;
        case 0:
            priority = "Low";
            break;
        default:
            priority = 'No Prio';
            break;
    }
    return (
        <React.Fragment>{priority}</React.Fragment>
    );
}
