import {
  FETCH_ADMIN_PROJECTS,
  UPDATE_ADMIN_PROJECT,
  UPDATE_ADMIN_PROJECT_STATUS,
  SET_USER_ROLE,
  FETCH_USERS,
  FETCH_DESIGNERS,
  SEARCH_ADMIN_PROJECTS,
} from "../actions/types";

const INITIAL_STATE = {
  projects: [],
  users: [],
  designers: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ADMIN_PROJECTS:
      return {
        ...state,
        projects: [...action.payload],
      };

    case SEARCH_ADMIN_PROJECTS:
      return {
        ...state,
        projects: action.payload,
      };

    case FETCH_DESIGNERS:
      return {
        ...state,
        designers: [...action.payload],
      };

    case UPDATE_ADMIN_PROJECT:
      return {
        ...state,
        projects: state.projects.map((project) => {
          if (project._id === action.payload._id) {
            return action.payload;
          }

          return project;
        }),
      };

    case UPDATE_ADMIN_PROJECT_STATUS:
      return {
        ...state,
        projects: state.projects.map((project) => {
          if (project._id === action.payload._id) {
            return action.payload;
          }

          return project;
        }),
      };

    case SET_USER_ROLE:
      return {
        ...state,
        users: state.users.map((user) => {
          if (user._id === action.payload._id) return action.payload;

          return user;
        }),
      };

    case FETCH_USERS:
      return {
        ...state,
        users: action.payload,
      };

    default:
      return state;
  }
};
