import React, { useEffect, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import axios from "axios";

import CardSection from "./CardSection";

export default function CardSetupForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState("");
  const [clientSecret, setClientSecret] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const getSecret = async () => {
      const getSecret = await axios.post("/users/get-client-secret", null);

      setClientSecret(getSecret.data.client_secret);
      setLoading(false);
    };

    getSecret();
  }, []);

  async function handleSubmit(event) {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
		}
		
		setErrorMessage(null);
		if (!name) {
			setErrorMessage('Name is required');
			return;
		}
    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name,
        },
      },
    });

    if (result.error) {
      // Display result.error.message in your UI.
      setErrorMessage(result.error.message);
    } else {
      // The setup has succeeded. Display a success message and send
      // result.setupIntent.payment_method to your server to save the
      // card to a Customer
      const saveMethod = await axios.post("/users/payment-method", {
        paymentMethod: result.setupIntent.payment_method,
      });
      props.handleUpdate();
      setLoading(true);
      setSuccess(true);
    }
  }

  return (
    <React.Fragment>
      <br />
      <br />
      <h2 className="account__title">Add or Replace Payment Method</h2>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      {success ? (
        <p>Payment Method Saved</p>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="form form--new-project block-padding"
        >
          <div>
            <label htmlFor="name">Name</label>
            <input
              id="name"
							type="name"
							required
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <CardSection />
          <button disabled={!stripe && loading} className="button button--dark">
            Save Card
          </button>
        </form>
      )}
    </React.Fragment>
  );
}
