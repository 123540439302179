import React, { useState } from "react";
import axios from "axios";
import UserHeader from "../user/UserHeader";
import ProfileMenu from "../user/ProfileMenu";
import Loading from "../Loading";
import { useSelector } from "react-redux";

function Subscription() {
  const [numProjects, setNumProjects] = useState("5-10");
  const [details, setDetails] = useState("");
  const [wasSent, setWasSent] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const role = useSelector((state) => state.auth.user.role);

  const onSubmit = async () => {
    try {
      setIsSending(true);
      const response = await axios.post("/users/sub-request", {
        avgDesigns: numProjects,
        details,
      });

      console.log(response.data);
      setWasSent(response.data.success);
      setIsSending(false);
    } catch (error) {
      console.log(error.message);
      setIsSending(false);
    }
  };

  const renderSuccessMessage = () => {
    return (
      <p className="form__instructions">
        Thank you! Your request has been sent. You will hear from us shortly.
      </p>
    );
  };

  const renderForm = () => {
    return (
      <React.Fragment>
        {role !== "subscriber" ? (
          <div className="form">
            <p className="form__instructions">
              You do not currently have a subscription. Please fill out the form
              to get a quote on a monthly subscription.
            </p>
            <label htmlFor="project">Average # of Designs Per Month</label>
            <div className="select">
              <i className="fad fa-angle-down"></i>
              <select
                name="project"
                id="project"
                value={numProjects}
                onChange={(e) => setNumProjects(e.target.value)}
              >
                <option value="5-10">5-10</option>
                <option value="11-20">11-20</option>
                <option value="21+">21+</option>
              </select>
            </div>
            <label htmlFor="message">Details</label>
            <textarea
              id="message"
              placeholder="Tell us how we can help!"
              onChange={(e) => setDetails(e.target.value)}
            ></textarea>
            {isSending ? (
              <Loading />
            ) : (
              <button
                onClick={onSubmit}
                type="submit"
                className="button button--dark"
              >
                Send Message
              </button>
            )}
          </div>
        ) : (
          <div className="form">
            <p className="form__instructions">
              You are already on our subscription plan. Please contact us if you
              have any questions about your plan.
            </p>
          </div>
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <UserHeader />

      <div className="account container">
        <div className="account__view account__view--main block-padding">
          <h2 className="account__title">Subscription</h2>
          {wasSent ? renderSuccessMessage() : renderForm()}
        </div>
        <ProfileMenu route="subscription" />
      </div>
    </React.Fragment>
  );
}

export default Subscription;
