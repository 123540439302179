import React, { useState, useEffect } from "react";
import axios from "axios";
import UserHeader from "../user/UserHeader";
import ProfileMenu from "../user/ProfileMenu";

function Downloads() {
  const [projects, setProjects] = useState(null);

  useEffect(() => {
    const getProjects = async () => {
      const response = await axios.get("/projects");

      const filterProjects = response.data.data.projects.filter(
        (project) => project.status === "approved"
      );
      setProjects(filterProjects);
    };
    getProjects();
  }, []);

  const renderDownloadList = () => {
    return projects.map((project) => (
      <div key={project._id} className="downloads__item">
        <h3 className="downloads__title">{project.name}</h3>
        <h4 className="downloads__link">
          <a target="_blank" href={project.assets.location}>
            <i className="fad fa-download"></i>Download Project Files
          </a>
        </h4>
      </div>
    ));
  };

  const renderNoDownloads = () => {
    return (
      <div className="downloads__item">
        <h3 className="downloads__title">No Completed Project avaible</h3>
      </div>
    );
  };

  return (
    <React.Fragment>
      <UserHeader />
      <div className="account container">
        <div className="account__view account__view--main block-padding">
          <h2 className="account__title">Downloads</h2>
          <div className="downloads">
            {projects ? renderDownloadList() : renderNoDownloads()}
          </div>
        </div>
        <ProfileMenu route="downloads" />
      </div>
    </React.Fragment>
  );
}

export default Downloads;
