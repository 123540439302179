import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Modal from "../Modal";
import Loading from '../Loading';
import totalCost from "../../utils/totalCost";
import {useSelector} from "react-redux";

export default function ApproveDesign(props) {
  const [modal, setModal] = useState(false);
  const { _id, hours } = props.project;
	const [isLoading, setIsLoading] = useState(false);
	const [isTotalCostLoading, setIsTotalCostLoading] = useState(true);
	const [ hourlyRate, setHourlyRate] = useState(0);
	const [ minHourDeposit, setMinHourDeposit] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);
  const role = useSelector((state) => state.auth.user.role);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const renderCost = () => {
    return totalCost(hours, hourlyRate);
	};

	useEffect(() => {
		const getHourlyRate = async () => {
			try {
				const response = await axios.get('projects/rate');
				setHourlyRate(response.data.data.hourlyRate);
				setMinHourDeposit(response.data.data.minHourDeposit);
				setIsTotalCostLoading(false);
			} catch (error) {
				console.log(error);
			}
		}

		getHourlyRate();
	}, [])

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setErrorMessage(null);
      await axios.put("/projects/approve", { project_id: _id });
      closeModal();
      setIsLoading(false);
      props.render();
    } catch (error) {
      setErrorMessage(JSON.parse(error.request.response).error);
      setIsLoading(false);
    }
  };

  const displayCost = () => {
    return (
        <React.Fragment>
          {(role !== 'subscriber') ?
              <p className="form__instructions">
                The total cost of this project is ${renderCost().toFixed(2)}. Your account will be
                charged ${(renderCost() - (hourlyRate * minHourDeposit)).toFixed(2)} for the remaining balance upon project
                approval.
              </p> :
              <p className="form__instructions">The cost of this project is included in your subscription. If you have already exceeded your allotted budget for this month, you will be charged an additional fee. The total cost of this project without a subscription would be ${renderCost()}.</p>
          }
        </React.Fragment>
    );
  };

  const displayNoCost = () => {
    return (
      <p className="form__instructions">
        Your initial deposit covers the cost of the project, no further charges
        need to be made.
      </p>
    );
  };

  const renderModal = () => {
    return (
      <Modal onDismiss={closeModal}>
        <h2 className="modal__title">Approve {(role !== 'subscriber') ? '& Pay' : 'Design'}</h2>
				<div className="form">
					{
						!isTotalCostLoading ? (renderCost() - (hourlyRate * minHourDeposit)).toFixed(2) > 0 ? displayCost() : displayNoCost() : <Loading/>
					}
          <p className="form__instructions">
            A .zip file with the project files & deliverables will be made
            available on your account once the project is approved.
          </p>
          <br></br>
          <label htmlFor="instructions">Additional Comments</label>
          <textarea
            id="instructions"
            placeholder="We'd love to hear your feedback about how this project went!"
          ></textarea>
          <button
            disabled={isLoading}
            onClick={handleSubmit}
            className="button button--dark"
          >
            Approve Project{((renderCost() - (hourlyRate * minHourDeposit)).toFixed(2) > 0 && role !== 'subscriber') ? ': $' + (renderCost() - (hourlyRate * minHourDeposit)).toFixed(2) : ''}
          </button>
          <button
            onClick={closeModal}
            className="button button--light"
            style={{ marginLeft: "1rem" }}
          >
            Cancel
          </button>
        </div>
        {errorMessage && (
          <React.Fragment>
            <p>Error: {errorMessage}</p>
            <p>
              You can update your payment information{" "}
              <Link className="text-button" to="/account/payment-method">
                Here.
              </Link>
            </p>
          </React.Fragment>
        )}
      </Modal>
    );
  };

  return (
    <React.Fragment>
      <button
        onClick={openModal}
        className="button button--dark project-view__approval__action project-view__approval__action--approve"
      >
        Approve Design
      </button>
      {modal && renderModal()}
    </React.Fragment>
  );
}
