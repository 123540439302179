import React, { useState, useEffect } from "react";
import UserHeader from "../user/UserHeader";
import ProfileMenu from "../user/ProfileMenu";
import CardInfoForm from "../payment/CardInfoForm";
import Loading from "../Loading";
import axios from "axios";

function PaymentDetails() {
  const [isLoading, setIsLoading] = useState(false);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const [cardInfo, setCardInfo] = useState({});

  useEffect(() => {
    const getPaymentMethod = async () => {
      setIsLoading(true);
      const response = await axios.post("/users/method", {});
      setHasPaymentMethod(response.data.hasPaymentMethod);
      setCardInfo(response.data.card);
      setIsLoading(false);
    };

    getPaymentMethod();
  }, []);

  const noCardInfo = () => {
    return <div>No card payment yet. Please add one below.</div>;
  };

  const updatePaymentMethod = async () => {
    const response = await axios.post("/users/method", {});
    setHasPaymentMethod(response.data.hasPaymentMethod);
    setCardInfo(response.data.card);
  };

  const validCardInfo = () => {
    return cardInfo.cardBrand ? (
      <div>
        <h2 className="account__title">Current Payment Method</h2>
        <p>
          <span style={{ fontWeight: 600 }}>Card Type</span>:{" "}
          {cardInfo.cardBrand.toUpperCase()}
        </p>
        <p>
          <span style={{ fontWeight: 600 }}>Card Name</span>:{" "}
          {cardInfo.cardName}
        </p>
        <p>
          <span style={{ fontWeight: 600 }}>Card Number</span>: **** **** ****{" "}
          {cardInfo.cardNumber}
        </p>
      </div>
    ) : <div>Error getting card payment info</div>
	};
	
	// cardInfo.cardBrand

  const renderCardInfo = () => {
    return hasPaymentMethod ? validCardInfo() : noCardInfo();
  };

  return (
    <React.Fragment>
      <UserHeader />
      <div className="account container">
        <div className="account__view account__view--main block-padding">
          {isLoading ? <Loading /> : renderCardInfo()}
          <CardInfoForm handleUpdate={() => updatePaymentMethod()} />
        </div>
        <ProfileMenu route="payment-details" />
      </div>
    </React.Fragment>
  );
}

export default PaymentDetails;
