import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {useSelector} from "react-redux";

export default function NewProject() {
  const [title, setTitle] = useState("");
	const [reference, setReference] = useState("")
  const [instructions, setInstructions] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [disableForm, setDisableForm] = useState(false);
	const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
	const [hasAgreed, setHasAgreed] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState(null);
	const [ hourlyRate, setHourlyRate] = useState(0);
	const [ minHourDeposit, setMinHourDeposit] = useState(0);
  const history = useHistory();
  const role = useSelector((state) => state.auth.user.role);

  useEffect(() => {
    const checkPayment = async () => {
      const payment = await axios.post("/users/method", {});
			setHasPaymentMethod(payment.data.hasPaymentMethod);
			const response = await axios.get('projects/rate');
			setHourlyRate(response.data.data.hourlyRate);
			setMinHourDeposit(response.data.data.minHourDeposit);
			setIsLoading(false);
    };
    checkPayment();
  }, []);

  const handleSubmit = async (event) => {
		event.preventDefault();

		setDisableForm(true);

		setErrorMessage(null);

		if (!title) {
			setErrorMessage('Title is required.');
			setDisableForm(false);
			return;
		}
		if (!instructions) {
			setErrorMessage('Instructions are required.');
			setDisableForm(false);
			return;
		}
		if (!selectedFile) {
			setErrorMessage('Asset is required.');
			setDisableForm(false);
			return;
		}
		if (!hasAgreed) {
			setErrorMessage('Agreement is required.');
			setDisableForm(false);
			return;
		}

    const formData = new FormData();
    formData.append("name", title);
    formData.append("instructions", instructions);
    formData.append("file", selectedFile);
		if (reference) {
			formData.append('reference', JSON.stringify(reference.split(/\s+/)));
		}

    try {
      const response = await axios.post("/projects/create", formData);

      setDisableForm(false);

      if (!hasPaymentMethod && role === 'customer') {
        history.push("/account/payment-method");
      } else {
        history.push("/projects");
      }
    } catch (error) {
      setDisableForm(false);
      console.log(error.message);
    }
  };

  const isDisable = () => {
    if (disableForm) {
      return disableForm;
    }
  };

  const handleOnChage = (e) => {
    if (e.target.files[0]) {
      setSelectedFile(e.target.files[0]);

      if (e.target.files[0].type === "image/jpeg") {
        setFilePreview(URL.createObjectURL(e.target.files[0]));
      }
      if (e.target.files[0].type !== "image/jpeg") {
        setFilePreview(null);
      }
    }
	};
	
	const depositCost = () => {
		return (hourlyRate * minHourDeposit).toFixed(2);
	}

  return (
    <React.Fragment>
      <header className="container">
        <div className="dashboard-header dashboard-header--new-project">
          <h1 className="dashboard-header__client-name">
            <a href="/projects" className="text-button">
              <i className="far fa-angle-left"></i> Back to Projects
            </a>
            <br></br>
            <span>New Project</span>
          </h1>
          <span className="dashboard-header__project-cost">{(role !== 'subscriber') ? <React.Fragment>${depositCost()} Deposit</React.Fragment> : <React.Fragment></React.Fragment>}</span>
        </div>
      </header>
      <div className="project-view project-view--new-project container">
        <form
          onSubmit={handleSubmit}
          className="form form--new-project block-padding"
        >
          <label htmlFor="title">Project Title</label>
          <input
            type="text"
						id="title"
						required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Choose a name for this campaign..."
          />
          <label htmlFor="instructions">Project Instructions</label>
          <textarea
						id="instructions"
						required
            value={instructions}
            onChange={(e) => setInstructions(e.target.value)}
						placeholder="Please be as clear as you can, and give references if possible!&#13;&#13;
						1. The logo will be called Purp's Purp&#13;
						2. We need the design on a standard mylar bar&#13;
						3. Add the Government Warning on the back"
          ></textarea>
          <label htmlFor="title">References (Optional)</label>
					<textarea
					id="reference"
					value={reference}
					onChange={(e) => setReference(e.target.value)}
					placeholder="Add multiple references images here with a DropBox or Google Drive link.&#13;&#13;Please make sure the link is publicly accessible!"
				></textarea>
          <label htmlFor="instructions">Project Instructions</label>
          <label htmlFor="zip" className="upload-block" style={{overflow: 'hidden'}}>
            {filePreview ? <img src={filePreview}></img> : "+ Upload Assets"}
          </label>
          <input
            type="file"
            id="zip"
						name="zip"
            accept=".zip,.rar,.7zip,.jpg,.jpeg,.png"
            className="upload-block"
            style={{ display: "none" }}
            onChange={handleOnChage}
          ></input>
					{selectedFile && <p>File Name: {selectedFile.name}</p>}
					<div className="margin-top-small" style={{ display: "flex" }}>
						<input 
						type="checkbox" 
						id="agreement" 
						name="agreement"
						onChange={(e) => setHasAgreed(!hasAgreed)}
						checked={hasAgreed}
						></input>
						<label htmlFor="agreement" className="margin-left-small">Agree to <a style={{textDecoration: 'underline'}} href="https://topshelfdesign.co/pages/terms-of-service" target="_blank">terms and services</a></label>
					</div>
          <button
            disabled={isDisable()}
            type="submit"
            className="button button--dark"
          >
            Create Project{(role !== 'subscriber') && <React.Fragment>(${depositCost()})</React.Fragment>}
					</button>
					{errorMessage && <p style={{color: 'red'}}>{errorMessage}</p>}
        </form>
      </div>
    </React.Fragment>
  );
}
