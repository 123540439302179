export const FETCH_ADMIN_PROJECTS = "FETCH_ADMIN_PROJECTS";
export const UPDATE_ADMIN_PROJECT = "UPDATE_PROJECT";
export const UPDATE_ADMIN_PROJECT_STATUS = "UPDATE_PROJECT_STATUS";
export const SET_USER_ROLE = "SET_USER_ROLE";
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_DESIGNERS = "FETCH_DESIGNERS";
export const VERIFY_TOKEN = "VERIFY_TOKEN";
export const LOG_OUT = "LOG_OUT";
export const LOG_IN = "LOG_IN";
// Normal Actions
export const FETCH_PROJECTS = "FETCH_PROJECTS";
// Search
export const SEARCH_ADMIN_PROJECTS = "SEARCH_ADMIN_PROJECTS";
export const SEARCH_PROJECTS = "SEARCH_PROJECTS";
