import React, { useState } from "react";
import axios from "axios";
import Modal from "../Modal";

export default function RequestEdits(props) {
  const [modal, setModal] = useState(false);
  const [comments, setComments] = useState("");
  const { _id } = props.project;

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const requestEdits = async () => {
    try {
      await axios.put("/projects/revise", { project_id: _id, comments });
      setModal(false);
      props.render();
    } catch (error) {
      console.log(error.message);
    }
  };

  const renderModal = () => {
    return (
      <Modal onDismiss={closeModal}>
        <h2 className="modal__title">Request Edits</h2>
        <p>Cofirm request.</p>
        <label htmlFor="comments">Comments:</label>
        <input
          type="text"
          id="comments"
          placeholder="Please enter you revision comments"
          onChange={(e) => setComments(e.target.value)}
        />
        <button onClick={requestEdits} className="button button--dark">
          Confirm
        </button>
        <button
          onClick={closeModal}
          className="button button--light"
          style={{ marginLeft: "1rem" }}
        >
          Cancel
        </button>
      </Modal>
    );
  };
  return (
    <React.Fragment>
      <button
        onClick={openModal}
        className="button button--light project-view__approval__action project-view__approval__action--request-revisions"
      >
        Request Edits
      </button>
      {modal && renderModal()}
    </React.Fragment>
  );
}
