import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { verifyToken } from "./actions";
import axios from "axios";
import PrivateRoute from "./PrivateRoute";
import AdminRoute from "./AdminRoute";

import Header from "./components/layouts/Header";
import Footer from "./components/layouts/Footer";
import Home from "./components/pages/Home";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Recovery from "./components/auth/Recovery";
import Reset from "./components/auth/Reset";
import NotFound from "./components/pages/NotFound";
import AdminPanel from "./components/pages/AdminPanel";
import Project from "./components/pages/Project";
import CardInfoForm from "./components/payment/CardInfoForm";
import Projects from "./components/pages/Projects";
import NewProject from "./components/pages/NewProject";
import Account from "./components/pages/Account";
import EditProject from "./components/admin/EditProject";
import Downloads from "./components/pages/Downloads";
import Subscription from "./components/pages/Subscription";
import PaymentDetails from "./components/pages/PaymentDetails";
import ThankRegistration from "./components/pages/ThankRegistration";

function App() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkLoggedIn = async () => {
      await dispatch(verifyToken());
      axios.defaults.headers.common["x-auth-token"] = await token;
      setLoading(false);
    };
    checkLoggedIn();
  }, []);

  return (
    <BrowserRouter>
      <Header />
      <main className="main">
        {!loading && (
          <Switch>
            <Route exact path="/register" component={Register} />
            <Route exact path="/registered" component={ThankRegistration} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/recovery" component={Recovery} />

            <Route exact path="/reset/:id" component={Reset} />

            <AdminRoute exact path="/admin">
              <AdminPanel />
            </AdminRoute>

            <PrivateRoute exact path="/projects/:id">
              <Project />
            </PrivateRoute>

            <AdminRoute exact path="/projects/:id/edit">
              <EditProject />
            </AdminRoute>

            <PrivateRoute exact path="/projects">
              <Projects />
            </PrivateRoute>

            <PrivateRoute exact path="/new-project">
              <NewProject />
            </PrivateRoute>

            <PrivateRoute exact path="/payment-info">
              <CardInfoForm />
            </PrivateRoute>

            <PrivateRoute exact path="/account">
              <Account />
            </PrivateRoute>

            <PrivateRoute exact path="/account/downloads">
              <Downloads />
            </PrivateRoute>

            <PrivateRoute exact path="/account/payment-method">
              <PaymentDetails />
            </PrivateRoute>

            <PrivateRoute exact path="/account/subscription">
              <Subscription />
            </PrivateRoute>

            <Route exact path="/" strict>
              <Redirect to="/projects" />
            </Route>
            <Route component={NotFound} />
          </Switch>
        )}
      </main>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
