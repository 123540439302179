import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "../../actions";

export default function AuthOptions() {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const register = () => history.push("/register");
  const login = () => history.push("/login");
  const onLogout = () => {
    history.push("/");
    dispatch(logout());
  };

  return (
    <>
      {user ? (
        <button className="text-button" onClick={onLogout}>
          <i className="far fa-angle-left"></i> Log Out
        </button>
      ) : (
        <>
          <button className="text-button" onClick={register}>
            Register
          </button>
          <button className="text-button last" onClick={login}>
            Log in
          </button>
        </>
      )}
    </>
  );
}
