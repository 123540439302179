import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import Modal from "../Modal";

export default function ApproveDesign(props) {
	const history = useHistory();
  const [modal, setModal] = useState(false);
  const { _id } = props.project;
	const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setErrorMessage(null);
      await axios.put("/projects/delete", { project_id: _id });
      closeModal();
      setIsLoading(false);
			history.push("/");
    } catch (error) {
      setErrorMessage(JSON.parse(error.request.response).error);
      setIsLoading(false);
    }
  };

  const renderModal = () => {
    return (
      <Modal onDismiss={closeModal}>
        <h2 className="modal__title">Delete Project</h2>
				<div className="form">
          <p className="form__instructions">
						Are you sure you want to delete this project permanently?
          </p>
          <button
            disabled={isLoading}
            onClick={handleSubmit}
            className="button button--red"
          >
            Delete Project
          </button>
          <button
            onClick={closeModal}
            className="button button--light"
            style={{ marginLeft: "1rem" }}
          >
            Cancel
          </button>
        </div>
        {errorMessage && (
          <React.Fragment>
            <p>Error: {errorMessage}</p>
            <p>
              You can update your payment information{" "}
              <Link className="text-button" to="/account/payment-method">
                Here.
              </Link>
            </p>
          </React.Fragment>
        )}
      </Modal>
    );
  };

  return (
    <React.Fragment>
      <button
        onClick={openModal}
        className="button button--red"
      >
        Delete Project
      </button>
      {modal && renderModal()}
    </React.Fragment>
  );
}
