import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function ProfileMenu(props) {
  const { route } = props;
  const user = useSelector((state) => state.auth.user);

  const [data, setData] = useState({
    order: "asc",
    status: "",
  });

  return (
    <div className="account__sidebar block-padding">
      <h2 className="account__title">Menu</h2>
      <ul>
        <li className={route === "account" ? "active" : ""}>
          <Link to="/account">Profile</Link>
        </li>
        <li className={route === "downloads" ? "active" : ""}>
          <Link to="/account/downloads">Downloads</Link>
        </li>
        {user.role !== "admin" &&
          user.role !== "designer" &&
          user.isSubElegible && (
            <li className={route === "subscription" ? "active" : ""}>
              <Link to="/account/subscription">Subscription</Link>
            </li>
          )}
        {user.role !== "admin" && user.role !== "designer" && (
          <li className={route === "payment-details" ? "active" : ""}>
            <Link to="/account/payment-method">Payment Details</Link>
          </li>
        )}
        {user.role === "admin" && user.role !== "designer" && (
          <li className={route === "admin" ? "active" : ""}>
            <Link to="/admin">Manage Users</Link>
          </li>
        )}
      </ul>
    </div>
  );
}

export default ProfileMenu;
