import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";

export default function AdminRoute({ children, ...rest }) {
  const token = useSelector((state) => state.auth.token);
  const role = useSelector((state) => state.auth.user.role);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        token && role === "admin" ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
