import React, { useState } from "react";
import Modal from "../Modal";
import { useDispatch } from "react-redux";
import { setUserRole } from "../../actions";

export default function SetRole({ user }) {
  const [modal, setModal] = useState(false);
  const [selection, setSelection] = useState('customer');
  const dispatch = useDispatch();

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
	};

  const renderModal = () => {
    return (
      <Modal onDismiss={closeModal}>
        <div>
          <h3>{user.firstName} {user.lastName}</h3>
            <br/>
          {roleForm()}
        </div>
      </Modal>
    );
  };

  const onSubmit = async () => {
    await dispatch(setUserRole(user._id, selection));
    closeModal();
  };

  const roleForm = (params) => {
    return (
      <div>
        <label htmlFor="role">Change User Role</label>
        <select
          id="role"
          defaultValue="customer"
          onChange={(event) => setSelection(event.target.value)}
        >
          {["admin", "designer", "customer", "subscriber"].map((role) => (
            <option key={role} value={role}>
              {role}
            </option>
          ))}
        </select>
        <div>
          <button onClick={closeModal} className="button button--light" style={{marginRight: "15px"}}>Cancel</button>
          <button onClick={onSubmit} className="button button--dark">Submit</button>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <button onClick={openModal}><i className="fad fa-user"></i> Change User Role</button>
      {modal && renderModal()}
    </React.Fragment>
  );
}
