const getStatusKeys = (admin = false) => {
  const statusKeys = [
    { display: "Pending", value: "pending" },
    { display: "Accepted", value: "accepted" },
    { display: "In Progress", value: "inprogress" },
    { display: "Needs Approval", value: "approval" },
    { display: "Revisions Requested", value: "revision" },
    { display: "Cancelled", value: "canceled" },
    { display: "Approved", value: "approved" },
  ];

  return statusKeys;
};

export default getStatusKeys;
