import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ProjectList from "../user/ProjectList";
import ProjectListAdmin from "../admin/ProjectListAdmin";
import { verifyToken } from "../../actions";

function Projects() {
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   const validateToken = async () => {
  //     await dispatch(verifyToken());
  //     setLoading(false);
  //   };

  //   validateToken();
  // }, []);

  const renderPanel = () => {
    // refactored this so that it redirects to login if the user isn't logged in. once we implement the modals for login/register we will need to update this
    if (!user) {
      return <p></p>;
    }
    switch (user.role) {
      case "admin":
        return <ProjectListAdmin />;
      default:
        return <ProjectList />;
    }
  };

  return <>{!loading && renderPanel()}</>;
}

export default Projects;
