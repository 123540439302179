import {
  FETCH_ADMIN_PROJECTS,
  UPDATE_ADMIN_PROJECT,
  UPDATE_ADMIN_PROJECT_STATUS,
  SET_USER_ROLE,
  FETCH_USERS,
  FETCH_DESIGNERS,
  VERIFY_TOKEN,
  LOG_OUT,
  LOG_IN,
  FETCH_PROJECTS,
  SEARCH_ADMIN_PROJECTS,
  SEARCH_PROJECTS,
} from "./types";

const axios = require("axios");

export const fetchAdminProjects = (filter) => async (dispatch) => {
  const { order, status, assigned, priority } = filter;
  // Set Params
  const params = new URLSearchParams();
  order && params.append("order", order);
  status && params.append("status", status);
  priority && params.append("priority", priority);
  assigned && params.append("assigned", assigned);

  const response = await axios("/projects", { params });

  dispatch({
    type: FETCH_ADMIN_PROJECTS,
    payload: response.data.data.projects,
  });
};

export const searchAdminProjects = (search) => async (dispatch) => {
  const response = await axios.get(`/projects/search/${search}`);

  dispatch({
    type: SEARCH_ADMIN_PROJECTS,
    payload: response.data.data.searchResults,
  });
};

export const fetchDesigners = () => async (dispatch) => {
  const response = await axios("/users", { params: { role: "designer" } });

  dispatch({
    type: FETCH_DESIGNERS,
    payload: response.data.data.users,
  });
};

export const updateProject = (project) => async (dispatch) => {
  dispatch({
    type: UPDATE_ADMIN_PROJECT,
    payload: project,
  });
};

export const updateProjectStatus = (project_id, status) => async (dispatch) => {
  const response = await axios.put("/projects/status", {
    project_id,
    status,
  });
  dispatch({
    type: UPDATE_ADMIN_PROJECT_STATUS,
    payload: response.data.data.project,
  });
};

export const setUserRole = (id, role) => async (dispatch) => {
  const response = await axios.put("/users/role", {
    id,
    role,
  });
  dispatch({
    type: SET_USER_ROLE,
    payload: response.data.data.user,
  });
};

export const fetchUsers = (filter) => async (dispatch) => {
  const { role } = filter;
  // Set Params
  const params = new URLSearchParams();
  role && params.append("role", role);
  const response = await axios("/users", { params });

  dispatch({
    type: FETCH_USERS,
    payload: response.data.data.users,
  });
};

// Auth
export const verifyToken = () => async (dispatch) => {
  let token = localStorage.getItem("auth-token");

  try {
    if (token === null) {
      localStorage.setItem("auth-token", "");
      token = "";
    }
    const response = await axios.post("/users/tokenisvalid", null, {
      headers: { "x-auth-token": token },
    });

    if (!token) {
      return;
    }

    dispatch({
      type: VERIFY_TOKEN,
      payload: response.data.user,
    });
  } catch (error) {
    localStorage.setItem("auth-token", "");
    token = "";
  }
};

export const login = (user) => async (dispatch) => {
  // Store Token
  await localStorage.setItem("auth-token", user.token);
  axios.defaults.headers.common["x-auth-token"] = await user.token;

  // Store to Redux
  dispatch({
    type: LOG_IN,
    payload: user,
  });
};

export const logout = () => (dispatch) => {
  // Remove auth token from localstorage
  localStorage.setItem("auth-token", "");

  dispatch({
    type: LOG_OUT,
  });
};

// Normal Actions
export const fetchProjects = (filter) => async (dispatch) => {
  const { order, status, assigned, priority } = filter;
  // Set Params

  const params = new URLSearchParams();
  status && params.append("status", status);
  priority && params.append("priority", priority);

  const response = await axios("/projects", { params });

  dispatch({
    type: FETCH_PROJECTS,
    payload: response.data.data.projects,
  });
};

export const searchProjects = (search) => async (dispatch) => {
  const response = await axios.get(`/projects/search/${search}`);

  dispatch({
    type: SEARCH_PROJECTS,
    payload: response.data.data.searchResults,
  });
};
