import React, { useState, useEffect } from "react";
import axios from "axios";

function Hour(props) {
  const { designers, hour, id, reload } = props;
  const [editing, setEditing] = useState(false);
  const [newHour, setNewHour] = useState("");

  const designer = designers.find((designer) => designer._id === hour.designer);

  const renderEditForm = () => {
    return (
      <div>
        <label htmlFor={hour.id}>New Hour Value</label>
        <input
          type="number"
          min="0"
          id={hour.id}
          value={newHour}
          onChange={(e) => setNewHour(e.target.value)}
          style={{ marginBottom: "1rem" }}
        />
        <button className="button button--dark" onClick={handleSubmit}>
          Submit
        </button>
        <button
          style={{ marginLeft: "0.5rem" }}
          className="button button--light"
          onClick={() => setEditing(false)}
        >
          Cancel
        </button>
      </div>
    );
  };

  const handleSubmit = async () => {
    try {
      await axios.put("/projects/edit-hours", {
        project_id: id,
        hour_id: hour.id,
        new_hours: newHour,
      });
      setEditing(false);
      reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  const renderHourBlock = () => {
    if (designer) {
      return (
        <li>
          <p>
            Designer: {designer.firstName} {designer.lastName}
          </p>
          <p>Session Hours: {hour.hours}</p>
          <br />
          <button
            onClick={() => setEditing(true)}
            className="button button--light"
          >
            Edit
          </button>
          {editing && renderEditForm()}
        </li>
      );
    } else {
      return <p>Error getting hour log</p>;
    }
  };

  return <React.Fragment>{renderHourBlock()}</React.Fragment>;
}

export default Hour;
