import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CardSetupForm from "./CardSetupForm";
let stripeKey;

const isProductionHost =
  window.location.hostname.trim() === "warm-dawn-76865.herokuapp.com" ||
  window.location.hostname.trim() === "app.topshelfdesign.co";

if (process.env.NODE_ENV === "production" && isProductionHost) {
  console.log("Production!");
  stripeKey =
    "pk_live_51HyIXWLiWeBxIfheGquqBmbfFx3IGjFOyQ8YJdDqxjQg3RfSXi2XECFiQ3gE03BuYriGjXIHroDRUUHLxDtCwr5M00gfcb8ux6";
} else {
  console.log("Development");
  stripeKey =
    "pk_test_51Hg16jLCRc8KeZRL00TJUdwkOtdhLavvvvBZs2ZBlwfqSZZsEyaaEdXWwnhSSkp0WrIlQEZruyX8XLnrmkvQUHjN00UK04kDh5";
}
const stripePromise = loadStripe(stripeKey);

export default function CardInfoForm(props) {
  return (
    <Elements stripe={stripePromise}>
      <CardSetupForm handleUpdate={props.handleUpdate} />
    </Elements>
  );
}
