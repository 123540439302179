import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Modal from "../Modal";

function ContactSupport() {
  const projects = useSelector((state) => state.user.projects);
  const [modal, setModal] = useState(false);
  const [subject, setSubject] = useState("");
  const [project, setProject] = useState("No Project");
  const [message, setMessage] = useState("");

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const handleSubmit = async () => {
    try {
      const response = axios.put("/projects/support", {
        subject,
        project,
        message,
      });
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };

  const renderModal = () => {
    return (
      <Modal onDismiss={closeModal}>
        <h2 className="modal__title">Contact Support</h2>
        <div className="form">
          <label htmlFor="subject">Message Subject</label>
          <input
            type="text"
            id="subject"
            placeholder="Subject"
            onChange={(e) => setSubject(e.target.value)}
          />
          <label htmlFor="project">Select Project</label>
          <div className="select">
            <i className="fad fa-angle-down"></i>
            <select
              name="project"
              id="project"
              value={project}
              onChange={(e) => setProject(e.target.value)}
            >
              <option value="No Project">No Project</option>
              {projects.map((project) => (
                <option key={project.name} value={project.name}>
                  {project.name}
                </option>
              ))}
            </select>
          </div>
          <label htmlFor="message">Your Message</label>
          <textarea
            id="message"
            placeholder="Write your message here"
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <button
            onClick={handleSubmit}
            type="submit"
            className="button button--dark"
          >
            Send Message
          </button>
          <button
            onClick={closeModal}
            className="button button--light"
            style={{ marginLeft: "1rem" }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      <button onClick={openModal} className="button button--dark">
        Contact Support
      </button>
      {modal && renderModal()}
    </React.Fragment>
  );
}

export default ContactSupport;
