import React, { useState } from "react";
import { useParams } from "react-router";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

function Reset() {
  const { id } = useParams();
  const history = useHistory();
  const [password, setPassword] = useState();
  const [passwordCheck, setPasswordCheck] = useState();
  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState();
  const [error, setError] = useState();

  const submit = async (e) => {
    e.preventDefault();
    setSending(true);

    try {
      await axios.put("/users/reset", {
        token: id,
        password,
        passwordCheck,
      });
      setMessage("Success! You will now be redirected.");
      setTimeout(function () {
        history.push("/login");
      }, 3000);
    } catch (error) {
      setError(error.response.data.msg);
      setSending(false);
    }
  };

  return (
    <React.Fragment>
      <header className="container">
        <div className="dashboard-header dashboard-header--account">
          <h1 className="dashboard-header__client-name">
            <span>Password Reset</span>
          </h1>
          <Link to="/login" className="button button--dark push-left">
            Login Instead
          </Link>
        </div>
      </header>
      <form onSubmit={submit} className="form form--new-project block-padding">
        <label htmlFor="password">New Password</label>
        <input
          id="password"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />

        <label htmlFor="verify-password">Verify New Password</label>
        <input
          id="verify-password"
          type="password"
          onChange={(e) => setPasswordCheck(e.target.value)}
        />
        <button disabled={sending} className="button button--dark">
          Submit
        </button>
        {message && <p>{message}. You will now be redirected.</p>}
        {error && <p>{error}. Pease try again.</p>}
      </form>
    </React.Fragment>
  );
}

export default Reset;
