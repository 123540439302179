const getStatus = (status) => {
  switch (status) {
    case "pending":
      return "pending";
    case "accepted":
      return "accepted";
    case "inprogress":
      return "in-progress";
    case "approval":
      return "needs-approval";
    case "revision":
      return "revisions-requested";
    case "canceled":
      return "cancelled";
    case "approved":
      return "approved";
    default:
      break;
  }
};

export default getStatus;
