import { VERIFY_TOKEN, LOG_IN, LOG_OUT } from "../actions/types";

const INITIAL_STATE = {
  user: null,
  token: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VERIFY_TOKEN:
      return {
        ...state,
        user: action.payload,
        token: localStorage.getItem("auth-token"),
      };

    case LOG_IN:
      return {
        ...state,
        ...action.payload,
      };

    case LOG_OUT:
      return {
        ...state,
        user: null,
        token: null,
      };

    default:
      return state;
  }
};
