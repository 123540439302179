import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function UserHeader() {
  const user = useSelector((state) => state.auth.user);
  const isCustomer = user.role === "customer" || user.role === "subscriber";

  return (
    <header className="account container">
      <div className="dashboard-header">
        <h1 className="dashboard-header__client-name">
          <span>{user.name}</span>
        </h1>
        {isCustomer && (
          <Link to="/new-project" className="button button--dark push-left">
            New Project
          </Link>
        )}
      </div>
    </header>
  );
}

export default UserHeader;
