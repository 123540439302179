import { FETCH_PROJECTS, SEARCH_PROJECTS } from "../actions/types";

const INITIAL_STATE = {
  projects: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PROJECTS:
      return {
        ...state,
        projects: [...action.payload],
      };

    case SEARCH_PROJECTS:
      return {
        ...state,
        projects: action.payload,
      };

    default:
      return state;
  }
};
