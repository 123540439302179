import React, { useState, useEffect } from "react";
import Modal from "../Modal";
import axios from "axios";

function UploadDesign(props) {
  const [modal, setModal] = useState(false);
  const [assetFile, setAssetFile] = useState(null);
  const [hours, setHours] = useState("0");
  const [thumbnail, setThumbnail] = useState(null);
  const [previews, setPreviews] = useState(null);
  const [previewsMessage, setPreviewsMessage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadingMesssage, setUploadingMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
	const [isFormDisable, setIsFormDisable] = useState(false);
	const [uploadUrl, setUploadUrl] = useState('');
	const id = props.id;

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsFormDisable(true);

    if (!thumbnail) {
      setErrorMessage("You must upload a thumbnail.");
      setIsFormDisable(false);
      return;
		}
		try {
			
		} catch (error) {
			
		}
		try {
    setErrorMessage("");
    if (thumbnail) {
      const formThumbnail = new FormData();
      formThumbnail.append("id", id);
      formThumbnail.append("file", thumbnail);

      setIsUploading(true);
      setUploadingMessage("Uploading thumbnail...");
      await axios.post("/projects/upload/thumbnail", formThumbnail);
      setUploadingMessage("Thumbnail uploaded...");
      setIsUploading(false);
    }
    if (uploadUrl) {		
			const designInfo = {
				id,
				hours,
				fileKey: uploadUrl,
				fileLocation: uploadUrl
			}
      setIsUploading(false);

      setIsUploading(true);
			setUploadingMessage("Saving hours...");
			await axios.post("/projects/upload", designInfo);
			setUploadingMessage("Hours Saved...");

      setIsUploading(false);
    }
    if (previews) {
      const formPreviews = new FormData();
      formPreviews.append("id", id);
      formPreviews.append("file", previews);
      previews.forEach((file) => {
        formPreviews.append("file", file);
      });

      setIsUploading(true);
      setUploadingMessage("Uploading previews...");
      await axios.post("/projects/upload/previews", formPreviews);
      setUploadingMessage("Previews uploaded...");
      setIsUploading(false);
    }


    closeModal();
    setThumbnail(null);
    setPreviews(null);
    setAssetFile(null);
    setIsFormDisable(false);
		setHours(null);
		// props.render();
		window.location.reload(false);
    } catch (error) {
      console.log(error.message);
      setErrorMessage("Something went wrong please try again.");
      setIsFormDisable(false);
    }
  };

  const renderPreviews = () => {
    return previews.map((image) => {
      return (
        <p
          className="form__instructions form__instructions--label"
          key={image.name}
        >
          {image.name}
        </p>
      );
    });
  };

  // Limit previews to 4 files
  useEffect(() => {
    if (previews) {
      if (previews.length > 4) {
        setPreviews(null);
        setPreviewsMessage("Maximun of 4 image previews allowed");
      }
    }
  }, [previews]);

  const renderModal = () => {
    return (
      <Modal onDismiss={closeModal}>
        <form className="form" onSubmit={handleSubmit}>
          <label>01. Upload Main Preview File</label>
          <p className="form__instructions">
            Upload a 1460x1000px jpg or png file with the design centered in the
            image file. If JPG please use white background.
          </p>
          <label htmlFor="thumbnail" className="button button--light">
            + Upload Thumbnail
          </label>
          <input
            type="file"
            id="thumbnail"
            name="thumbnail"
            accept="image/png, image/jpeg"
            style={{ display: "none" }}
            onChange={(e) => setThumbnail(e.target.files[0])}
          ></input>
          <span className="form__instructions form__instructions--label">
            {thumbnail ? thumbnail.name : ""}
          </span>
          <br></br>
          <br></br>
          <br></br>
          <label>02. Upload All Preview Files (Optional)</label>
          <p className="form__instructions">
            Upload additional preview files using the same format as the main
            preview above. These will appear as thumbnails to the customer.
          </p>
          <label htmlFor="previews" className="button button--light">
            + Upload Previews
          </label>
          <input
            type="file"
            id="previews"
            name="previews"
            accept="image/png, image/jpeg"
            multiple={true}
            style={{ display: "none" }}
            onChange={(e) => {
              setPreviews(Array.from(e.target.files));
            }}
          ></input>
          {previewsMessage && (
            <p
              class="form__instructions"
              style={{ color: "red", marginTop: "15px" }}
            >
              Error: {previewsMessage}
            </p>
          )}
          {previews && renderPreviews()}
          <br></br>
          <br></br>
          <br></br>
          <label>03. Uploaded Deliverables</label>
          <p className="form__instructions">
						Submit uploaded deliverable link.
          </p>
					<label htmlFor="url">Design Url</label>
          <input
            type="url"
            id="url"
            placeholder="Design url"
            onChange={(e) => setUploadUrl(e.target.value)}
          />
          <span className="form__instructions form__instructions--label">
            {assetFile ? assetFile.name : ""}
          </span>
          <br></br>
          <br></br>
          <br></br>
          <label htmlFor="instructions">Current Hours</label>
          <input
            type="text"
            id="instructions"
            placeholder="Please enter the total # of hours as a decimal. i.e. 3.5 hours."
            onChange={(e) => setHours(e.target.value)}
          />
          <button
            type="submit"
            className="button button--dark"
            disabled={isFormDisable}
          >
            Upload Files
          </button>
          <button
            onClick={closeModal}
            className="button button--light"
            style={{ marginLeft: "1rem" }}
            disabled={isFormDisable}
          >
            Cancel
          </button>
          {isUploading && (
            <span className="form__upload-status">
              Progress: {uploadingMesssage}
            </span>
          )}
          {errorMessage && (
            <p
              className="form__instructions"
              style={{ color: "red", marginTop: "15px" }}
            >
              Error: {errorMessage}
            </p>
          )}
        </form>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      <button
        onClick={openModal}
        className="button button--dark project-view__title__action project-view__title__action--request-packaging"
      >
        Upload Design
      </button>
      {modal && renderModal()}
    </React.Fragment>
  );
}

export default UploadDesign;
